<template>
  <div id="phone-auth">
    <div class="welcome" v-if="confirmed">
      <h3>{{ loginMessage }}</h3>
    </div>
    <div class="code-entry" v-else-if="pendingConfirmation">
      <form @submit.prevent="enteredCode" v-if="!confirmed">
        <div class="phonebox">
          <input ref="verify" :disabled=disabled v-model="code" placeholder="Enter the code" type="text" inputmode="numeric" autocomplete="one-time-code">
          <button class="big-button" :disabled=disabled>Enter code</button>
        </div>
      </form>
    </div>
    <div class="phone-entry" v-else>
      <form @submit.prevent="enteredPhone">
        <div class="phonebox">
          <input :loading=loading :disabled=disabled v-model="inputtedPhoneNumber" placeholder="Enter your phone number">
          <button class="big-button" :loading=loading :disabled=disabled>Send text</button>
        </div>
      </form>

    </div>
    <h3 v-if="errorMessage" class="errorMsg">{{errorMessage}}</h3>
  </div>
</template>

<script>
import axios from 'axios';
import parsePhoneNumber from 'libphonenumber-js/min';

export default {
  name: 'PhoneAuth',
  props: ['regionPromo'],
  data() {
    return {
      inputtedPhoneNumber: this.$store.state.phoneNumber, // what's in the input
      submittedPhoneNumber: null, // what was sent to create a code
      pendingConfirmation: null,
      code: null,
      loading: false,
      errorMessage: '',
      firstLogin: null,
    };
  },
  created() {
    if (this.confirmed) {
      this.$emit('authenticated', false);
    }
  },
  methods: {
    enteredPhone() {
      if (!this.inputtedPhoneNumber) {
        console.log("No inputted number");
        return;
      } else if (this.inputtedPhoneNumber === this.submittedPhoneNumber) {
        console.log("Inputted number didn't change");
        return;
      }

      this.code = null;
      this.$store.dispatch('logout');

      console.log(this.inputtedPhoneNumber);
      const phoneNumber = parsePhoneNumber(this.inputtedPhoneNumber, 'CA');

      if (phoneNumber.isValid()) {
        this.submittedPhoneNumber = phoneNumber.number;
        this.loading = true;
        // post to server
        axios.post('verify_number/', {
          phone_number: this.submittedPhoneNumber,
        }).then((response) => {
          this.pendingConfirmation = true;
          this.loading = false;

          this.$nextTick(() => {
            this.$refs.verify.focus();
          });
          
        }).catch(error => {
          console.log(error);
          this.loading = false;
          this.errorMessage = error.response.data;
        })
      }
    },
    enteredCode() {
      if (!this.code) {
        return;
      }

      if (this.code.length !== 4) {
        // yeet
        this.errorMessage = "Please enter a 4-digit code";
      }
      this.loading = true;

      var data = {
        phoneNumber: this.submittedPhoneNumber,
        code: this.code,
      };

      if (this.$route.query.email) {
        data.email = this.$route.query.email;
      }

      this.$store.dispatch('login', data).then((response) => {
        console.log("Logged in callback called");
        // emit event: you're logged in babyyy
        this.errorMessage = null;
        this.firstLogin = response.data.first_login;
        this.$emit('authenticated', this.firstLogin);
        this.loading = false;
      }).catch((error) => {
        console.log("Error callback happened");
        this.loading = false;
        if (error.response) {
          this.errorMessage = error.response.data.detail;
        } else {
          this.errorMessage = "Something went wrong."
        }
      });        
    }
  },
  computed: {
    confirmed: function() {
      return this.$store.state.phoneNumber != '';
    },
    disabled: function() {
      return this.confirmed || this.loading;
    },
    loginMessage: function() {
      if (this.firstLogin) {
        return "Welcome to the club!";
      } else {
        return "Welcome back!";
      }
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
h3 { // success message
  font-size: 48px;
  font-family: $header-font;
  color: white;
  margin: 0;
  font-style: italic;
  // add shadow to the font only
  text-shadow: 2px 2px 0px $emphasis;
  // box-shadow: 0px -2px 0px 0px rgba(0, 0, 0, 0.25) inset;
  &.errorMsg {
    color: black;
    text-shadow: none;
    font-size: 22px;
    text-decoration: underline;
    text-decoration-color: red;
  }
}
</style>
